import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@env';
import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  /**
   * Get notification data from REA history
   */

  getHistoryNotifications(orderId: string, tenant: string): Observable<any> {
    const url = `${environment.apiUrl}/notifications/notifications/history?orderId=${orderId}`;

    const headers = new HttpHeaders({
      'X-Tenant': tenant,
    });

    return this.http.get(url, {headers});
  }


  /**
   * Get archived documents
   */

  getArchivedDocuments(orderId: string, tenant: string): Observable<any> {
    const url = `${environment.apiUrl}/documents/documents?orderId=${orderId}`;

    const headers = new HttpHeaders({
      'X-Tenant': tenant
    });

    return this.http.get(url, {headers});
  }

  /**
   * Get migrated documents
   */

  getMigratedDocuments(orderId: string, tenant: string): Observable<any> {
    const url = `${environment.apiUrl}/documents/documents/search?isMigrated=1&orderId=${orderId}`;

    const headers = new HttpHeaders({
      'X-Tenant': tenant
    });

    return this.http.get(url, {headers});
  }

  /**
   * Resend email notifications
   */
  resendNotification(resendInformation, tenant): Observable<any> {
    const url = `${environment.apiUrl}/notifications-resend/notifications/${resendInformation.id}`;
    const to = '{"to": "' + resendInformation.email + '"}' || "";

    const headers = new HttpHeaders({
      'Content-Type': 'application/x.com.media-saturn.rea.recipients-overrides+json',
      'X-Tenant': tenant
    });

    return this.http.post(url, to, {headers}).pipe();
  }

  /**
   * Resend migrated documents
   */
  resendMigratedDocuments(resendInformation, tenant): Observable<any> {
    const url = `${environment.apiUrl}/notifications-resend/documents/resend/aroma/${resendInformation.id}`;
    const to = `{"toAddress":"${resendInformation.email}", "firstName":"${resendInformation.firstName}", "lastName": "${resendInformation.lastName}"}`;


    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Tenant': tenant
    });

    return this.http.post(url, to, {headers}).pipe();
  }

  /**
   * Retrieve message associated to the request
   */
  getMessageRequest(requestId, tenant): Observable<any> {
    const requestUrl = `${environment.apiUrl}/events/requests?id=${requestId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Tenant': tenant
    });

    return this.http.get(requestUrl, {headers}).pipe();
  }


  /**
   * Retrieve migrated document content
   */
  getArchivedDocumentContent(itemId, tenant): Observable<any>{
    const requestUrl = `${environment.apiUrl}/documents/documents/${itemId}`;

    const headers = new HttpHeaders({
      'Accept': 'application/pdf',
      'X-Tenant': tenant
    });

    return this.http.get(requestUrl, {
      headers,
      observe: 'response',
      responseType: 'arraybuffer' as any
    }).pipe();
  }

  /**
   * Retrieve history attachment document content
   */
  getHistoryAttachmentContent(itemId, filename, tenant): Observable<any>{
    const requestUrl = `${environment.apiUrl}/notifications/notifications/${itemId}/attachment?document_name=${encodeURIComponent(filename)}`;

    const headers = new HttpHeaders({
      'Accept': 'application/pdf',
      'X-Tenant': tenant
    });

    return this.http.get(requestUrl, {
      headers,
      observe: 'response',
      responseType: 'arraybuffer' as any
    }).pipe();
  }


  /**
   * Retrieve history email content
   */
  getHistoryEmailContent(itemId, tenant): Observable<any>{
    const requestUrl = `${environment.apiUrl}/notifications/notifications/${itemId}`;

    const headers = new HttpHeaders({
      'Accept': 'application/x.com.media-saturn.rea.full-email+json',
      'X-Tenant': tenant
    });

    return this.http.get(requestUrl, {
      headers,
      observe: 'response',
      responseType: 'json' as any
    }).pipe();
  }

}
