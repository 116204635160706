import {AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ArchivedDocument, EmailHistorySearch, MigratedDocuments, Status} from '@core/search/search-summary';
import {SearchService} from '@core/search/search.service';
import {forkJoin, Observable, of} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {BaseComponent} from '@utils/base.component';
import {ModalService} from '@shared/modal/modal.service';
import {ResendSearchModalComponent} from '@shared/search-common/resend-search-modal/resend-search-modal.component';
import {AlertService} from '@shared/alert/alert.service';
import {catchError, tap} from 'rxjs/operators';

@Component({
  templateUrl: './call-center-search.component.html',
  styleUrls: ['./call-center-search.component.scss']
})
export class CallCenterSearchComponent extends BaseComponent implements OnInit, OnDestroy {

  public tenant;
  public order;
  public statuses = Status;
  public emailList: EmailHistorySearch[];
  public archivedDocuments: ArchivedDocument[];
  public migratedDocuments: MigratedDocuments[];
  public loadingSpinner = true;


  constructor(
    private searchService: SearchService,
    private modalFactory: ModalService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.pipe(
      this.unsubscribeOnDestroy
    )
      .subscribe((params) => {
        // tenant and orderId are read from query params

        this.order = params.orderId || params.orderNumber;
        this.tenant = params.distributionChannel;


        /* !! temporary workaround so that if 'sterling-' does not come as part of the distributionChannel's value  it will be added */
        if (this.tenant && this.tenant !== 'testing' && this.tenant.split('-').length < 2) {
          this.tenant = 'sterling-' + this.tenant;
        }

        /* !! temporary workaround so that if tenant contains _ it will be removed */
        if (this.tenant) {
          this.tenant = this.tenant
            .toLowerCase()
            .split('_')
            .join('');
        }

        // to accommodate any potential legacy code, we keep the token with the key "ngStorage-token"
        if (params.token) {
          localStorage.setItem('ngStorage-token', JSON.stringify(params.token));
        }

        this.searchRequests(params);

      });
  }

  searchRequests(params): void {
    const requests = [
      this.getEmails(),
      this.getDocuments(),
    ];

    if (params.isMigrated && this.tenant !== 'sterling-mmde' && this.tenant !== 'sterling-sede') {
      requests.push(this.getMigratedDocuments());
    }

    forkJoin(requests).subscribe(() => {
      this.loadingSpinner = false;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getEmails(): Observable<any> {
    if (this.tenant == null || this.order == null) {
      return of(null);
    }

    this.emailList = [];

    return this.searchService.getHistoryNotifications(this.order, this.tenant).pipe(tap((emailsHistory) => {
      emailsHistory.map(el => el.notification = true);

      this.emailList = emailsHistory;
      this.emailList.map(el => {
        el.status = (el.status === this.statuses.Delivered) ? this.statuses.Sent : el.status;
        el.metadata.resendOf = el.metadata['resend-of'];
      });
      this.cdr.detectChanges();
    })).pipe(
      catchError(async (error) =>
        this.alertService.showError('An error occurred in the retrieving process for email notifications!')
      ));
  }


  getDocuments(): Observable<void> {
    if (this.tenant == null || this.order == null) {
      return of(null);
    }

    this.archivedDocuments = [];

    return this.searchService.getArchivedDocuments(this.order, this.tenant)
      .pipe(tap((archivedDocuments) => {
        this.archivedDocuments = archivedDocuments;
      })).pipe(
        catchError(async (error) =>
          this.alertService.showError('An error occurred in the retrieving process for archived documents!')
        ));
  }

  getMigratedDocuments(): Observable<any> {
    if (this.tenant == null || this.order == null) {
      return of(null);
    }

    this.migratedDocuments = [];

    return this.searchService.getMigratedDocuments(this.order, this.tenant)
      .pipe(tap((migratedDocuments) => {
        migratedDocuments.map(el => {
          el.name = `${el.metadata.AROMA_FIRST_NAME} ${el.metadata.AROMA_LAST_NAME}`;
          el.emailAddress = el.metadata.AROMA_MAIL_ADDRESS;
          el.copyDocumentName = el.metadata.copyDocumentName;
        });
        this.migratedDocuments = migratedDocuments;
      })).pipe(
        catchError(async (error) =>
          this.alertService.showError('An error occurred in the retrieving process for archived documents!')
        ));
  }

  resendHistoryNotification(resendInformation): void {
    this.modalFactory.open(ResendSearchModalComponent, {}).subscribe(response => {
      if (response) {
        this.handleResendRequest(this.searchService.resendNotification(resendInformation, this.tenant), () => {
          this.loadingSpinner = true;
          this.getEmails().subscribe(() => {
            this.loadingSpinner = false;
          });
        });
      }
    });
  }


  resendMigrationNotification(resendInformation): void {
    this.modalFactory.open(ResendSearchModalComponent, {}).subscribe(response => {
      if (response) {
        this.handleResendRequest(this.searchService.resendMigratedDocuments(resendInformation, this.tenant), () => {
          this.getEmails().subscribe(() => {
            this.loadingSpinner = true;
            this.getEmails().subscribe(() => {
              this.loadingSpinner = false;
            });
          });
        });
      }
    });
  }

  private handleResendRequest(obs: Observable<any>, fetch: () => void): void {
    obs.subscribe(() => {
      this.alertService.showSuccess(`The E-Mail has been sent once again!`);
      fetch();
    }, () => {
      this.alertService.showError(`We encountered an error while resending the E-Mail!`);
    });
  }

  handleDownloadRequest(request): void {
    if (request?.requestId == null || this.tenant == null ) {
      return;
    }

    this.searchService.getMessageRequest(request.requestId, this.tenant).subscribe(
      response => {

        const blob = new Blob([JSON.stringify(response)], {type: 'text/csv'});
        const fileName = `${request.id}.json`;
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      }, () => {
        this.alertService.showError(`We encountered an error while downloading the file!`);
      });
  }
}
