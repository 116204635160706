import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment
} from '@angular/router';
import {ApplicationService} from '@core/store/application.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private appService: ApplicationService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.appSelected();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.appSelected();
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> {
    return this.appSelected();
  }

  /**
   * Checks if the user selected an application to use.
   * @returns True if user should be allowed on the route; False if route should not be navigated to
   */
  private appSelected(): Observable<boolean> {
    return this.appService.get().pipe(
      take(1),
      map((app) => {
        const exists = !!app;
        if (!exists) {
          this.router.navigate(['/dashboard']);
        }
        return exists;
      })
    );
  }
}
