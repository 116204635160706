import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ArchivedDocument} from '@core/search/search-summary';
import {MatPaginator} from '@angular/material/paginator';
import {PdfPreviewModalComponent, PdfPreviewModalData} from '@shared/modal/pdf-preview-modal/pdf-preview-modal.component';
import {ModalService} from '@shared/modal/modal.service';
import {SearchService} from '@core/search/search.service';
import {AlertService} from '@shared/alert/alert.service';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'rea-search-documents',
  templateUrl: './search-documents.component.html'
})
export class SearchDocumentsComponent implements AfterViewInit, OnInit, OnChanges {

  @Input() data: Array<ArchivedDocument>;
  @Input() tenant: any;

  @Output() requestEmitter = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;

  dataSource = new MatTableDataSource<ArchivedDocument>();

  displayedColumns: string[] = ['document', 'type', 'prepared_at', 'actions'];


  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private modalFactory: ModalService,
    private searchService: SearchService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void{
    this.dataSource.data = this.data || [];
  }

  ngOnChanges(changes): void{
    if (changes){
      this.dataSource.data = this.data || [];
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
  }

  downloadRequest(id, requestId): void{
    this.requestEmitter.emit({id, requestId});
  }

  previewDocument(item): void{
    this.searchService.getArchivedDocumentContent(item.id, this.tenant).subscribe( res => {
      this.showPdf(res.body, item.filename);
    }, error => {
      this.alertService.showError(error.message);
    });
  }

  showPdf(file, filename): void {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
    } else {
      this.modalFactory.open(PdfPreviewModalComponent, {
        base64: file,
        title: filename ? `Preview file ${filename}` : `Preview`
      } as PdfPreviewModalData);
    }
  }

}
