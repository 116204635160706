import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {EmailHistorySearch, Status} from '@core/search/search-summary';
import {HtmlPreviewModalComponent} from '@shared/modal/html-preview-modal/html-preview-modal.component';
import {PdfPreviewModalComponent, PdfPreviewModalData} from '@shared/modal/pdf-preview-modal/pdf-preview-modal.component';
import {ModalService} from '@shared/modal/modal.service';
import {SearchService} from '@core/search/search.service';
import {AlertService} from '@shared/alert/alert.service';
import {MatSort} from '@angular/material/sort';
import { UserService } from "@core/store/user.service";

@Component({
  selector: 'rea-search-emails',
  templateUrl: './search-emails.component.html'
})
export class SearchEmailsComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() data: Array<EmailHistorySearch>;
  @Input() tenant: string;
  dataSource = new MatTableDataSource<EmailHistorySearch>();

  @Output() resendHistoryEmitter = new EventEmitter();
  @Output() requestEmitter = new EventEmitter();

  displayedColumns: string[] = ['email', 'status', 'attachment', 'emailAddress', 'timestamp', 'actions'];

  public statusList = Status;
  readonly pattern = /.{1,}@[1-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}$/;

  public currentPermissions: string[] | undefined;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private modalFactory: ModalService,
    private searchService: SearchService,
    private alertService: AlertService,
    private userService: UserService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.dataSource.data = this.sortEmails() || [];
    this.userService.get().subscribe(user => {
      this.currentPermissions = user?.rights;
    });
  }

  ngOnChanges(changes): void {
    if (changes) {
      this.dataSource.data = this.data || [];
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
  }

  onFocusOut(element){
    element.to = element.to.replaceAll("\u200B", "");
  }

  resend(element): void {
    if (element.notification) {
      this.resendHistoryEmitter.emit({ email: element.to, id: element.id });
    }
  }

  downloadRequest(id, requestId): void {
    this.requestEmitter.emit({ id, requestId });
  }

  validateEmail(str: string): boolean {
    return this.pattern.test(str);
  }

  previewAttachment(itemId, attachment): void {
    this.searchService.getHistoryAttachmentContent(itemId, attachment.filename, this.tenant).subscribe(res => {
      this.showPdf(res.body, attachment.filename);
    }, error => {
      this.alertService.showError(error.message);
    });
  }

  previewEmail(item): void {
    if (item.notification) {
      this.previewHistoryEmail(item.id);
    }
  }


  previewHistoryEmail(id): void {
    this.searchService.getHistoryEmailContent(id, this.tenant).subscribe(res => {
      this.showEmail(res.body.body.content);

    }, error => {
      this.alertService.showError(error.message);
    });
  }

  showEmail(content): void {
    this.modalFactory.open(HtmlPreviewModalComponent, {
      data: content
    } as any);
  }


  showPdf(file, filename): void {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file);
    } else {
      this.modalFactory.open(PdfPreviewModalComponent, {
        base64: file,
        title: filename ? `Preview file ${filename}` : `Preview`
      } as PdfPreviewModalData);
    }
  }

  sortEmails(): EmailHistorySearch[] {
    const groupedList = [];
    const sortedByDate = this.data.sort((a, b) => b.timestamp - a.timestamp);

    sortedByDate.forEach(el => {
      if (!el.metadata.resendOf) {
        groupedList.push(el);
      }
    });
    sortedByDate.slice().reverse().forEach(el => {
      if (el.metadata.resendOf) {
        const root = this.findResendRoot(el);
        groupedList.splice(groupedList.indexOf(root) + 1, 0, el);
      }
    });
    return groupedList;
  }

  getById(id) {
    return this.data.find(el => el.id === id
    );
  }

  findResendRoot(el): EmailHistorySearch {
    if (el !== undefined && el?.metadata && !el.metadata['resend-of']) {
      return el;
    }
    if (el !== undefined && el?.metadata && el.metadata['resend-of']) {
      const elem = this.getById(el?.metadata['resend-of']);
      return this.findResendRoot(elem);
    }
  }
}
