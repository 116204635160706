import {Component, Inject, OnInit} from '@angular/core';
import {MODAL_DATA, MODAL_REF, ModalRef} from '@shared/modal/modal.component';

@Component({
  selector: 'rea-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(
    @Inject(MODAL_REF) private modalRef: ModalRef,
    @Inject(MODAL_DATA)public data: ConfirmationModalData) {
  }


  ngOnInit(): void {
  }

  submit(): void {
    this.modalRef.close(true);
  }

  cancel(): void {
    this.modalRef.close();
  }

}

export interface ConfirmationModalData {
  title: string;
  description: string;
  okLabel: {content: string, color: Color};
  cancelLabel: {content: string, color: Color};
}

export enum Color {
  warn = 'WARN',
  primary= 'PRIMARY',
  neutral = 'NEUTRAL'
}
